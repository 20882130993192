import RegForm from "../components/RegForm";
import AuthView from "../views/auth/AuthView";
import Home from "../views/Home";
import MainView from "../views/MainView";
import ThankYou from "../views/ThankYou";
import ThankYouNee from "../views/ThankYouNee";

let routes = [
    // {
    //     path: "/auth",
    //     component: AuthView,
    //     layout: "auth",
    // },
    // {
    //     path: "/",
    //     component: Home,
    //     layout: "main",
    // },
    // {
    //     path: "/form",
    //     component: RegForm,
    //     layout: "main",
    // },
    // {
    //     path: "/thankyou",
    //     component: ThankYou,
    //     layout: "main",
    // },
    // {
    //     path: "/thankyou-nee",
    //     component: ThankYouNee,
    //     layout: "main",
    // },
];
export default routes;
